const LOCAL_STORAGE_KEYS = {
    TOKEN: "token",
    USER: "user",
    COMPANY_ID: "companyId",
    COMPANY_NAME: "companyName"
    // Add other keys as needed
};

const getItem = (key) => {
    return localStorage.getItem(key);
};

const setItem = (key, value) => {
    localStorage.setItem(key, value);
};

const removeItem = (key) => {
    localStorage.removeItem(key);
};

const clearAll = () => {
    Object.values(LOCAL_STORAGE_KEYS).forEach((key) => {
        localStorage.removeItem(key);
    });
};

export { LOCAL_STORAGE_KEYS, getItem, setItem, removeItem, clearAll };
