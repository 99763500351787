// src/components/DepartmentDistributionChart.jsx
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Chart } from 'react-google-charts';

const DEFAULT_COLORS = ['#675DF5', '#8D85FF', '#7874B6', '#443BC4'];
const GREY_COLOR = '#D3D3D3';

const DepartmentDistributionChart = () => {
    const [departmentData, setDepartmentData] = useState([]);
    const companyId = localStorage.getItem("companyId");

    useEffect(() => {
        const fetchEmployees = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/v2/employees/company/${companyId}/employees`);
                const employees = response.data.employees.filter(employee => !employee.isDeleted);
                const departmentCounts = employees.reduce((acc, curr) => {
                    const department = curr.department || 'Pas de données';
                    acc[department] = (acc[department] || 0) + 1;
                    return acc;
                }, {});

                const formattedData = [
                    ['Department', 'Count'],
                    ...Object.entries(departmentCounts).map(([key, value]) => [key, value])
                ];
                
                setDepartmentData(formattedData);
            } catch (error) {
                console.error('Failed to fetch employees:', error);
            }
        };

        fetchEmployees();
    }, []);

    const getColorArray = (data) => {
        const colorMap = new Map();
        let colorIndex = 0;

        return data.slice(1).map(item => {
            if (item[0] === 'Pas de données') {
                return GREY_COLOR;
            }
            if (!colorMap.has(item[0])) {
                colorMap.set(item[0], DEFAULT_COLORS[colorIndex % DEFAULT_COLORS.length]);
                colorIndex++;
            }
            return colorMap.get(item[0]);
        });
    };

    const colors = [GREY_COLOR, ...getColorArray(departmentData).filter(color => color !== GREY_COLOR)];

    return (
        <div className="chart-container" style={{ width: '22vw', margin: '0 auto', padding: '20px' }}>
            <h2 className="text-center mb-1 font-semibold">Départements de l'entreprise</h2>
            <p className='text-center text-sm text-slate-700'>Comment votre entreprise est-elle organisée ?</p>
            <Chart
                chartType="PieChart"
                data={departmentData}
                options={{
                    pieHole: 0.4,
                    legend: {
                        position: 'bottom', // Position the legend at the bottom
                        alignment: 'center', // Align legend to the center
                        maxLines: 2 // Allow legend to wrap into multiple lines
                    },
                    chartArea: { width: '90%', height: '80%' }, // Adjust chart area to add space for legend
                    colors: colors
                }}
                width="100%"
                height="300px"
            />
        </div>
    );
};

export default DepartmentDistributionChart;
