// src/pages/SamplePage.jsx
import React from 'react';
import DepartmentDistributionChart from '../Stats/DepartmentDistributionChart';
import GenderDistributionChart from '../Stats/GenderDistributionChart'; 
import TotalEmployeesStat from '../Stats/TotalEmployeesStat';
import TotalSentSurveysStat from '../Stats/TotalSentSurveysStat';
import SentSurveysChart from '../Stats/SentSurveysChart';
import CategoryAverageRating from '../Stats/CategoryAverageRating';
import MonthlyAverageRatingChart from '../Stats/MonthlyAverageRatingChart';
import CategorySurveyCount from '../Stats/CategorySurveyCount';
import { ChevronDownIcon,  StarIcon, FaceSmileIcon, ChartBarIcon, HomeIcon, BuildingOffice2Icon, FilterIcon, DocumentDuplicateIcon, LifebuoyIcon, UsersIcon, Cog6ToothIcon } from '@heroicons/react/24/outline'
import CategorySurveyCountCurrentMonth from '../Stats/CategorySurveyCountCurrentMonth';

const StatsPolitique
    = () => {
    return (
        <div className='flex flex-col bg-white border border-slate-100 rounded-xl px-8 py-6 pb-8'>
<div className='flex flex-row items-center justify-normal h-fit align-middle '>
            <BuildingOffice2Icon className='w-5 h-5 items-center mr-2 text-bsrate' />
                <div className='font-bold text-bsrate text-xl'>Politique d'entreprise</div>
                
            </div>
            <p className='text-sm text-slate-700 mb-6 mt-1'>Comment votre entreprise est-elle organisée ?</p>

            <div className='flex flex-col w-full  gap-x-8 mb-8'>
                <div className=' flex flex-col gap-y-4'>
                    <div className='grid grid-cols-2 gap-4'>
                                <CategoryAverageRating category="Politique d'entreprise" />

                    {/* <CategorySurveyCount category="Politique d'entreprise" /> */}
                        <CategorySurveyCountCurrentMonth category="Politique d'entreprise" />
                    </div>        <MonthlyAverageRatingChart category="Politique d'entreprise" />
                </div>
                
            </div>
            
        </div>
    );
};

export default StatsPolitique;
