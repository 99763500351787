import React from 'react';
import SignIn from '../components/Auth/SignIn';

const SignInPage = () => {
  return (
    <div class='bg-slate-100'>
      <div className='justify-center flex-col items-center h-screen'>
      <SignIn />
      
      
      </div>
      </div>
  );
};

export default SignInPage;
