import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { getAverageRatingByMonth } from '../../services/surveysService';
import { toast } from 'react-toastify';

const MonthlyAverageRatingChart = ({ category }) => {
  const [chartData, setChartData] = useState({
    labels: [],
    datasets: [
      {
        label: 'Average Rating',
        data: [],
        backgroundColor: 'rgba(75,192,192,0.4)',
        borderColor: 'rgba(75,192,192,1)',
        borderWidth: 1,
      },
    ],
  });

  useEffect(() => {
    const fetchAverageRatingByMonth = async () => {
      try {
        const data = await getAverageRatingByMonth(category);
        const months = data.monthlyRatings.map(item => `${item._id.month}/${item._id.year}`);
        const ratings = data.monthlyRatings.map(item => item.averageRating);

        setChartData({
          labels: months,
          datasets: [
            {
              label: 'Note moyenne',
              data: ratings,
              backgroundColor: '#675df6',
              borderColor: '#675df6',
              borderWidth: 0,
            },
          ],
        });
      } catch (error) {
        console.error('Failed to fetch average rating by month:', error);
        toast.error('Failed to fetch average rating by month');
      }
    };

    fetchAverageRatingByMonth();
  }, [category]);

  return (
    <div className="flex flex-col w-full p-4 bg-card border border-bsrate shadow rounded-lg">
      <h3 className="text-sm font-semibold text-bsrate mb-2">Note moyenne par mois</h3>
      <Bar data={chartData} />
    </div>
  );
};

export default MonthlyAverageRatingChart;
