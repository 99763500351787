import React from 'react';
import Sidebar from '../components/Base/Sidebar';
import PageTitle from '../components/Base/PageTitle';
import Sidebar2 from '../components/Base/Sidebar2';

const Help = () => {
    return (
<Sidebar2 title="Aide" >

    <div className="w-4/5 py-2 px-6">
            </div>
    </Sidebar2>
    );
};

export default Help;
