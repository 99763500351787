import React, { useEffect, useState } from 'react';
import { getSurveyCountByCategoryForCurrentMonth } from '../../services/surveysService';
import { toast } from 'react-toastify';

const CategorySurveyCountCurrentMonth = ({ category }) => {
  const [surveyCount, setSurveyCount] = useState(0);

  useEffect(() => {
    const fetchSurveyCount = async () => {
      try {
        const data = await getSurveyCountByCategoryForCurrentMonth(category);
        setSurveyCount(data.surveyCount);
      } catch (error) {
        console.error('Failed to fetch survey count:', error);
        toast.error("Failed to fetch survey count for current month");
      }
    };

    fetchSurveyCount();
  }, [category]);

  return (
    <div className="flex flex-col w-full p-4 bg-card border border-bsrate shadow rounded-lg">
      <h3 className="text-sm font-semibold text-bsrate mb-2">Enquêtes réalisées ce mois</h3>
      <div className="flex items-center">
        <span className="ml-2 text-xl font-bold text-bsrate">{surveyCount}</span>
      </div>
    </div>
  );
};

export default CategorySurveyCountCurrentMonth;
