import React, { useEffect, useState } from 'react';
import { getAllTextAnswers } from '../../services/surveysService';
import { TagCloud } from 'react-tagcloud';
import { toast } from 'react-toastify';

const WordCloudComponent = () => {
  const [words, setWords] = useState([]);

  useEffect(() => {
    const fetchTextAnswers = async () => {
      try {
        const data = await getAllTextAnswers();
        const textAnswers = data.textAnswers.join(' ');
        const wordCounts = getWordCounts(textAnswers);
        setWords(wordCounts);
      } catch (error) {
        console.error('Failed to fetch text answers:', error);
        toast.error("Failed to fetch text answers");
      }
    };

    fetchTextAnswers();
  }, []);

  const getWordCounts = (text) => {
    const sanitizedText = text.replace(/[.,/#!$%^&*;:{}=\-_`~()]/g, ""); // Remove punctuation
    const wordsArray = sanitizedText.split(/\s+/);
    const wordCounts = wordsArray.reduce((counts, word) => {
      word = word.toLowerCase();
      if (word.length >= 3) {
        counts[word] = (counts[word] || 0) + 1;
      }
      return counts;
    }, {});

    return Object.keys(wordCounts).map(word => ({ value: word, count: wordCounts[word] }));
  };

  const customRenderer = (tag, size, color) => (
    <span
      key={tag.value}
      style={{
        color: '#675df6',
        fontSize: `${size}px`,
        margin: '0 5px', // Add horizontal space
        display: 'inline-block', // Ensure words stay in a single line
      }}
      className={`tag-${size}`}
    >
      {tag.value}
    </span>
  );

  return (
    <div className="word-cloud-container bg-white p-8 rounded-xl h-full">
      <h3 className="text-xl font-semibold text-bsrate mb-2">Les mots de vos enquêtes</h3>
      <TagCloud
        minSize={12}
        maxSize={35}
        tags={words}
        renderer={customRenderer}
      />
    </div>
  );
};

export default WordCloudComponent;
