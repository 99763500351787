import React, { useEffect, useState } from 'react';
import { getAverageRatingByCategory } from '../../services/surveysService';
import { toast } from 'react-toastify';
import StarsBase from './StarsBase'; // Import the custom Star component

const CategoryAverageRating2 = ({ category, IconComponent, subtitle }) => {
  const [averageRating, setAverageRating] = useState(0);

  useEffect(() => {
    const fetchAverageRating = async () => {
      try {
        const data = await getAverageRatingByCategory(category);
        setAverageRating(data.averageRating);
      } catch (error) {
        console.error('Failed to fetch average rating:', error);
        toast.error("Failed to fetch average rating");
      }
    };

    fetchAverageRating();
  }, [category]);

  const renderStars = (rating) => {
    const totalStars = 5;
    const fullStars = Math.floor(rating);
    const partialStar = rating - fullStars;
    const stars = [];

    for (let i = 0; i < totalStars; i++) {
      if (i < fullStars) {
        stars.push(<StarsBase key={i} fill={100} />); // Full star
      } else if (i === fullStars) {
        stars.push(<StarsBase key={i} fill={partialStar * 100} />); // Partial star
      } else {
        stars.push(<StarsBase key={i} fill={0} />); // Empty star
      }
    }

    return <div className="flex">{stars}</div>;
  };

  return (
    <div className="flex flex-col w-full p-4 bg-card border bg-white border-none shadow rounded-lg">
      <div className="flex flex-row items-center justify-start mb-4">
        {IconComponent && <IconComponent className="h-4 w-4 mr-2 text-indigo-600" />}
        <h3 className="text-sm font-semibold text-bsrate">{category}</h3>
      </div>
      <div className="flex items-center mt-3">
        <span className="ml-0 mr-2 text-xl font-bold text-bsrate">{averageRating}</span>
        {renderStars(averageRating)}
      </div>
      {subtitle && <p className="text-xs text-slate-500">{subtitle}</p>}
    </div>
  );
};

export default CategoryAverageRating2;
