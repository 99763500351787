import React, { useEffect, useState } from "react";
import axios from "axios";

const PreviewSurveyModal = ({
  isOpen,
  title,
  category,
  description,
  closeModal,
  selectedSurvey,
}) => {
  const [question, setQuestion] = useState([]);
  const [surveyName, setSurveyName] = useState("");
  const [surveyDescription, setSurveyDescription] = useState("");
  const [surveyCategory, setSurveyCategory] = useState("");

  const assignQuestionOrderIfMissing = (questions) => {
    return questions.map((question, index) => {
      if (question.Question_order === undefined || question.Question_order === null) {
        return { ...question, Question_order: index + 1 };
      }
      return question;
    });
  };

  useEffect(() => {
    if (isOpen && selectedSurvey) {
      const fetchingSurveyDetails = async () => {
        try {
          const response = await axios.post(
            `${process.env.REACT_APP_BACKEND_URL}/api/v2/surveys/survey/answerdetails/${selectedSurvey._id}`
          );
          if (response) {
            setSurveyName(response.data[1].surveyName);
            setSurveyDescription(response.data[2].surveyDescription);
            setSurveyCategory(response.data[3].surveyCategory);
          }
        } catch (error) {
          console.error(error);
        }
      };
      fetchingSurveyDetails();
    }
  }, [isOpen, selectedSurvey]);

  useEffect(() => {
    if (isOpen && selectedSurvey) {
      const fetchingSurveyQuestions = async () => {
        try {
          const response = await axios.get(
            `${process.env.REACT_APP_BACKEND_URL}/api/v2/surveys/questions/${selectedSurvey._id}`
          );
          if (response) {
            const questionsWithOrder = assignQuestionOrderIfMissing(response.data);
            const sortedQuestions = questionsWithOrder.sort(
              (a, b) => a.Question_order - b.Question_order
            );
            setQuestion(sortedQuestions);
          }
        } catch (error) {
          console.error(error);
        }
      };
      fetchingSurveyQuestions();
    }
  }, [isOpen, selectedSurvey]);

  if (!isOpen) {
    return null;
  }

  return (
    <>
      <div
        className="fixed inset-0 bg-black bg-opacity-50 z-50 min-h-44"
        onClick={closeModal}
      ></div>
      <dialog className="modal smooth-open min-h-44" open={isOpen}>
        <div
          className="bg-white relative z-50 min-h-96 w-3/6 h-screen mt-5 mb-5 w-2/3 overflow-auto rounded-2xl shadow-lg"
          style={{ height: "95%" }}
        >
          <div className="p-8">
            <button
              className="btn btn-sm btn-circle absolute right-2 top-2"
              onClick={closeModal}
            >
              ✕
            </button>
            {selectedSurvey.imageUrl && (
              <img
                src={selectedSurvey.imageUrl}
                alt={`${selectedSurvey.name} Image`}
                className="h-48 w-full object-cover mb-4 rounded-lg"
              />
            )}
            <h5 className="text-sm text-slate-300 uppercase font-medium mb-2">Enquête</h5>
            <h1 className="font-bold text-3xl text-slate-900 mb-1">{title}</h1>
            <p className="text-lg text-slate-700 mt-1 mb-4">{description}</p>
            <p className="text-lg text-gray-700 mt-1 mb-4">{category}</p>
            <div className="p-6 bg-slate-100 rounded-xl">
              <h3 className="font-semibold mb-6 text-bsrate text-xl">Les questions</h3>
              {question.map((ques) => (
                <div className="p-0" key={ques._id}>
                  <h3 className="font-regular flex gap-3 mb-4 text-md">
                    <span className="bg-bsrate flex text-white text-sm items-center font-bold flex-wrap text-center justify-center w-6 h-6 max-w-6 min-w-6 max-h-6 min-h-6 rounded-full">
                      {ques.Question_order}
                    </span>
                    {ques.Question_title}
                  </h3>
                </div>
              ))}
            </div>
          </div>
        </div>
      </dialog>
    </>
  );
};

export default PreviewSurveyModal;
