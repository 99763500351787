import React, { useEffect, useState } from 'react';
import { getAverageRatingForAllSurveys } from '../../services/surveysService';
import { StarIcon } from '@heroicons/react/24/solid';
import { toast } from 'react-toastify';
import { ChartBarIcon } from '@heroicons/react/24/solid';

const AllSurveysAverageRating2 = () => {
  const [averageRating, setAverageRating] = useState(0);

  useEffect(() => {
    const fetchAverageRating = async () => {
      try {
        const data = await getAverageRatingForAllSurveys();
        setAverageRating(data.averageRating);
      } catch (error) {
        console.error('Failed to fetch average rating:', error);
        toast.error("Failed to fetch average rating");
      }
    };

    fetchAverageRating();
  }, []);

  const renderStars = (rating) => {
    const totalStars = 5;
    const fullStars = Math.floor(rating);
    const partialStar = rating - fullStars;
    const stars = [];

    for (let i = 0; i < totalStars; i++) {
      if (i < fullStars) {
        stars.push(
          <div key={i} className="relative h-6 w-6">
            <StarIcon className="absolute inset-0 text-white" />
            <StarIcon className="absolute inset-0 text-yellow-500" />
          </div>
        );
      } else if (i === fullStars) {
        stars.push(
          <div key={i} className="relative h-6 w-6">
            <StarIcon className="absolute inset-0 text-white" />
            <StarIcon
              className="absolute inset-0 text-yellow-400"
              style={{ clipPath: `inset(0 ${100 - partialStar * 100}% 0 0)` }}
            />
          </div>
        );
      } else {
        stars.push(
          <div key={i} className="relative h-6 w-6">
            <StarIcon className="absolute inset-0 text-white" />
          </div>
        );
      }
    }

    return <div className="flex">{stars}</div>;
  };

  return (
    <div className="flex flex-col w-full p-4 bg-card border bg-bsrate border-bsrate shadow rounded-lg">
      <div className="flex flex-row items-center justify-start mb-4">
        <ChartBarIcon className="h-4 w-4 mr-2 text-white" />
        <h3 className="text-sm font-semibold text-white">Note moyenne générale</h3>
      </div>
      <div className="flex items-center">
        <span className="ml-0 mr-2 text-3xl font-bold text-white">{averageRating}</span>
        {renderStars(averageRating)}
      </div>
      <p className="text-xs text-white">basée sur toutes les enquêtes</p>
    </div>
  );
};

export default AllSurveysAverageRating2;
