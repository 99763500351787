import React from 'react'
import { Link } from 'react-router-dom'

const SendSurveyMails = () => {
  return (
    <div className='flex items-center justify-center h-screen flex-col'>
      <h1 className='font-semibold text-[20px]'>L'enquête a bien été envoyée !</h1>
      <Link to='/dashboard' className='btn bg-bsrate mt-4 rounded-sm font-semibold'>Retour au Tableau de Bord</Link>
    </div>
  )
}

export default SendSurveyMails
