// services/surveyService.js
import axios from 'axios';

const API_URL = `${process.env.REACT_APP_BACKEND_URL}/api/v2/surveys`;

const getAllSurveys = async () => {
  return await axios.get(`${API_URL}/default/all`);
};

const getSurveyBySurveyId = async (id) => {
  return await axios.get(`${API_URL}/surveybyid/${id}`);
};

const getSurveyByAdminId = async (adminId) => {
  return await axios.get(`${API_URL}/survey/${adminId}`);
};

const getSurveyQuestionsById = async (surveyId) => {
  return await axios.get(`${API_URL}/questions/${surveyId}`);
};

const createSurvey = async (surveyData, adminId) => {
  return await axios.post(`${API_URL}/survey/create/${adminId}`, surveyData);
};

const updateSurvey = async (id, surveyData) => {
  return await axios.put(`${API_URL}/${id}`, surveyData);
};

const deleteSurvey = async (id) => {
  return await axios.delete(`${API_URL}/${id}`);
};

const getSentSurveysByCompanyId = async (companyId) => {
  return await axios.get(`${API_URL}/company/${companyId}/sentsurveys`);
};

const getAverageRatingByCategory = async (category) => {
  const response = await axios.get(`${API_URL}/average-rating/${category}`);
  return response.data;
};

const getAverageRatingByMonth = async (category) => {
  const response = await axios.get(`${API_URL}/average-rating-by-month/${category}`);
  return response.data;
};

const getSurveyCountByCategory = async (category) => {
  const response = await axios.get(`${API_URL}/survey-count/${category}`);
  return response.data;
};

const getAllTextAnswers = async () => {
  const response = await axios.get(`${API_URL}/text-answers`);
  return response.data;
};

 const getAverageRatingForAllSurveys = async () => {
  const response = await axios.get(`${API_URL}/average-rating-all`);
  return response.data;
};

const getCategoriesFromSurveys = async () => {
  const response = await getAllSurveys();
  const surveys = response.data.surveys;
  const categories = [...new Set(surveys.map(survey => survey.category))];
  return categories;
};

 const getSurveyCountByCategoryForCurrentMonth = async (category) => {
  const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/v2/surveys/survey-count-current-month`, {
    params: {
      category
    }
  });
  return response.data;
};


export {
  getAllSurveys,
  getSurveyBySurveyId,
  getSurveyByAdminId,
  getSurveyQuestionsById,
  createSurvey,
  updateSurvey,
  deleteSurvey,
  getSentSurveysByCompanyId,
  getAverageRatingByCategory,
  getAverageRatingByMonth,
  getSurveyCountByCategory,
  getAllTextAnswers,
  getAverageRatingForAllSurveys,
  getCategoriesFromSurveys,
  getSurveyCountByCategoryForCurrentMonth,
  
};
