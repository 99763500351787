import axios from "axios";
import React, { createContext, useContext, useState, useEffect } from "react";
import { LOCAL_STORAGE_KEYS, setItem, getItem, clearAll } from "./utils/localStorage";

const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
    const [authData, setAuthData] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const token = getItem(LOCAL_STORAGE_KEYS.TOKEN);
        const user = JSON.parse(getItem(LOCAL_STORAGE_KEYS.USER));

        if (token && user) {
            setAuthData({ token, user });
        }
        setLoading(false);
    }, []);

    const signIn = async (email, password) => {
        try {
            const response = await axios.post('/api/auth/login', { email, password });
            if (response.status === 201) {
                const { token, user } = response.data;
                setItem(LOCAL_STORAGE_KEYS.TOKEN, token);
                setItem(LOCAL_STORAGE_KEYS.USER, JSON.stringify(user));
                setItem(LOCAL_STORAGE_KEYS.COMPANY_ID, user.companyId);
                setItem(LOCAL_STORAGE_KEYS.COMPANY_NAME, user.companyName);
                setAuthData({ token, user });
                axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
            }
        } catch (error) {
            console.error("Login error:", error);
            // Handle login error (e.g., show error message to user)
        }
    };

    const signOut = () => {
        clearAll();
        setAuthData(null);
    };

    if (loading) {
        return <div></div>; // Loading indicator
    }

    return (
        <AuthContext.Provider value={{ authData, signIn, signOut }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => useContext(AuthContext);
