import React from "react";
import Modal from "react-modal";
import GenericModal from "../Base/GenericModal";

const AddEmployeeModal = ({
  modalIsOpen,
  closeModal,
  newEmployee,
  setNewEmployee,
  handleSubmit,
  customStyles,
}) => {
  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewEmployee((prevState) => ({ ...prevState, [name]: value }));
  };

  return (
    <GenericModal isOpen={modalIsOpen} closeModal={closeModal}>
      <h2 className="text-xl font-bold text-slate-900 mb-1">
        Ajouter un(e) employé(e)
      </h2>
      <h2 className="text-md font-regular text-slate-600">
        Ajoutez un nouvel employé à votre compte.
      </h2>
      <form onSubmit={handleSubmit} className="form-control w-full mt-4">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div>
            <div className="label">
              <span className="label-text">Prénom</span>
            </div>
            <input
              type="text"
              name="firstName"
              value={newEmployee.firstName}
              onChange={handleChange}
              placeholder="Bernard"
              className="input input-bordered w-full"
              required
            />
          </div>
          <div>
            <div className="label">
              <span className="label-text">Nom</span>
            </div>
            <input
              type="text"
              name="lastName"
              value={newEmployee.lastName}
              onChange={handleChange}
              placeholder="Tapie"
              className="input input-bordered w-full"
              required
            />
          </div>
        </div>
        <div className="mt-4">
          <div className="label">
            <span className="label-text">Adresse e-mail</span>
          </div>
          <input
            type="email"
            name="email"
            value={newEmployee.email}
            onChange={handleChange}
            placeholder="bernard@tapie.com"
            className="input input-bordered w-full"
            required
          />
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-4">
          <div>
            <div className="label">
              <span className="label-text">Département</span>
            </div>
            <input
              type="text"
              name="department"
              value={newEmployee.department}
              onChange={handleChange}
              placeholder="Administration"
              className="input input-bordered w-full"
              required
            />
          </div>
          <div>
            <div className="label">
              <span className="label-text">Poste</span>
            </div>
            <input
              type="text"
              name="position"
              value={newEmployee.position}
              onChange={handleChange}
              placeholder="Directeur général"
              className="input input-bordered w-full"
              required
            />
          </div>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-4">
          <div>
            <div className="label">
              <span className="label-text">Sexe</span>
            </div>
            <select
              name="gender"
              value={newEmployee.gender || ""}
              onChange={handleChange}
              className="input input-bordered w-full"
              required
            >
              <option value="">Sélectionner...</option>
              <option value="Homme">Homme</option>
              <option value="Femme">Femme</option>
              <option value="Autre">Autre</option>
            </select>
          </div>
          <div>
            <div className="label">
              <span className="label-text">Date de naissance</span>
            </div>
            <input
              type="date"
              name="dob"
              value={newEmployee.dob || ""}
              onChange={handleChange}
              className="input input-bordered w-full"
              required
            />
          </div>
        </div>
        <div className="mt-4">
          <div className="label">
            <span className="label-text">Type de contrat</span>
          </div>
          <input
            type="text"
            name="contractType"
            value={newEmployee.contractType}
            onChange={handleChange}
            placeholder="CDI, CDD, etc."
            className="input input-bordered w-full"
            required
          />
        </div>
        <button type="submit" className="btn btn-primary bg-bsrate text-white border-none mt-8 mb-1 text-md">
          Ajouter
        </button>
      </form>
    </GenericModal>
  );
};

export default AddEmployeeModal;
