// src/components/TotalSentSurveysStat.js
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { UsersIcon, DocumentCheckIcon } from '@heroicons/react/24/solid';
import { toast } from 'react-toastify';

const TotalSentSurveysStat2 = () => {
    const [totalSentSurveys, setTotalSentSurveys] = useState(0);
    const adminId = localStorage.getItem("userId");

    useEffect(() => {
        const fetchSentSurveys = async () => {
            try {
                const response = await axios.get(
                    `${process.env.REACT_APP_BACKEND_URL}/api/v2/surveys/survey/sended/${adminId}`
                );
                setTotalSentSurveys(response.data.surveys.length);
            } catch (error) {
                console.error('Failed to fetch sent surveys:', error);
                toast.error("Failed to fetch sent surveys");
            }
        };

        fetchSentSurveys();
    }, [adminId]);

    return (
        <div className="flex bg-bsrate flex-col w-full p-4 bg-card border border-bsrate shadow rounded-lg ">
            <div className="flex flex-row  items-center justify-start mb-4">
                <DocumentCheckIcon className="h-4 w-4 mr-2 text-white" />
                <h3 className="text-sm font-semibold text-white">Enquêtes envoyées</h3>
            </div>
            <div className="w-full min-w-full max-w-full flex flex-col">
                <div className="text-3xl font-bold text-white">{totalSentSurveys}</div>
                <p className="text-xs text-white">envoyée(s) sur BackstageRate</p>
            </div>
        </div>
    );
};

export default TotalSentSurveysStat2;
